<template>
	<div class="image-container" :loaded="!lazy || loaded" :loader="lazy && !loaded && !placeholder" :style="{backgroundImage: placeholder ? `url(${placeholder})` : null}">
		<img
			class="image"
			:src="src"
			@load="onLoad"
			:style="{
				objectFit: size,
				objectPosition: position,
				maxWidth: forceMax && originalSize ? `${originalSize.width}px` : null,
				maxHeight: forceMax && originalSize ? `${originalSize.height}px` : null,
			}"
			alt=""
		>
	</div>
</template>

<script>
export default {
	name: "BpsImage",
	props: {
		src: {
			type: String,
		},
		size: {
			type: String,
			default: 'cover'
		},
		position: {
			type: String,
			default: 'center'
		},
		lazy: {
			type: Boolean,
			default: true
		},
		placeholder: {
			type: String
		},
		delay: {
			type: Number,
			default: 300
		},
		forceMax: {
			type: Boolean,
			default: false
		},
	},
	data() {
		return {
			loaded: false,
			originalSize: null
		}
	},
	watch: {
		src() {
			clearTimeout(this.imgTO)
			this.originalSize = null
			this.loaded = false
		}
	},
	methods: {
		onLoad(e) {
			clearTimeout(this.imgTO)
			this.originalSize = { width: e.target.naturalWidth, height: e.target.naturalHeight }
			this.imgTO = setTimeout(() => this.loaded = true, this.delay)
		}
	}
};
</script>

<style scoped>
.image-container {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.image-container[loader]:before {
	display: none;
}

.image-container .image {
	width: 100%;
	opacity: 0;
	transition: opacity .16s ease-in;
}

.image-container[loaded] .image {
	opacity: 1;
}
</style>
