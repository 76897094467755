<template>
  <div class="article event">
    <img :src="event.image" :alt="event.title" class="article-image">
    <div class="article-details">
      <div class="article-author">
        <DateFormat :date="event.date" />
      </div>
      <h4>{{ event.title }}</h4>
      <div class="article-excerpt">{{getDescription(event.description)}}</div>
      <router-link :to="`/events/${event.seo.slug}`" class="btn medium ghost">
        Read more
      </router-link>
    </div>
  </div>
</template>

<script>
import Icon from "./Icon.vue";
import DateFormat from "./DateFormat.vue";

export default {
  name: "EventItem",
  components: {
    Icon,
    DateFormat,
  },
  props: {
    event: {
      type: Object,
    },
  },
  methods: {
    getDescription(content) {
      const div = document.createElement('div');
      div.innerHTML = content;
      const p = div.querySelector('p');
      if (!p) {
        return '';
      }
      return p.innerText.length > 200 ? p.innerText.substr(0, 197) + '...' : p.innerText;
    },
  },
};
</script>

<style scoped>
.article {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;
  border-radius: 12px;
  transition: all .2s ease-out;
}

#app[no-touch] .article:hover {
  transform: translateY(-8px);
  box-shadow: 0px 24px 32px 0px rgba(170, 170, 170, 0.08);
}

.article .article-details {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  padding: 32px 24px;
}

.article .article-details .btn {
  align-self: flex-start;
}

.article h4 {
  font-size: 24px;
  margin: 24px 0 12px;
  font-weight: 600;
  transition: color 0.2s ease-in;
}

.article .article-categories {
  font-size: 18px;
  text-transform: uppercase;
  color: var(--Metallic-Seaweed);
  min-height: 21px;
}

.article .article-author {
  color: var(--Metallic-Seaweed);
}

.article .article-excerpt {
  margin: 16px 0 32px;
  flex: 1;
  font-size: 20px;
  line-height: 1.4;
}

.article .article-excerpt::before {
  content: '';
  display: block;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin-bottom: 16px;
}

.article .article-image {
  display: block;
  width: 100%;
  height: 278px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  object-fit: cover;
  opacity: 0;
}

.article .article-image[src] {
  opacity: 1;
}

@media screen and (max-width: 1280px) {
  .article .article-image {
    height: 300px;
  }
}

@media screen and (max-width: 720px) {
  .article h4 {
    font-size: 32px;
  }
  .article .article-image {
    height: 260px;
  }
  .article .article-details {
    margin-top: 24px;
  }
}

@media screen and (max-width: 520px) {
  .article h4 {
    font-size: 24px;
  }
  .article .article-categories {
    font-size: 12px;
  }
  .article .article-details {
    padding: 0;
  }
}
</style>
